import React, { useState, useRef, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";

import SEO from "../components/seo";

import Hexaportal from "../images/hexportal.png";
import Loop from "../images/loop.png";
import Logo from "../images/logo.png";
// import TD from "../images/td.jpg";
import Sponsors from "../images/sponsors.png";
import HXOUSELogos from "../images/hxouse-logos.png";
// import ShadowTitleImg from "../images/title.png";
import ShadowTitleLightImg from "../images/title-light.png";
import ShadowNameImg from "../images/name.png";
import { check } from "prettier";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "URWAccidalia";
    font-style: normal;
    src: local("URWAccidalia"), local("URWAccidalia"),
      url("../fonts/URWAccidaliaTMedExt.ttf") format("truetype");
  }

  @font-face {
    font-family: "Univers";
    font-style: normal;
    src: local("Univers"), local("Univers"),
      url("../fonts/UniversLTPro-45Light.otf") format("opentype");
  }

  body {
    margin: 0;
    padding: 0;
    background: #DFE1E3;
    color: #6d7279;
    font-family: "URWAccidalia";
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${(props) =>
    props.fourRows ? "1fr 1fr 1fr 56px" : "1fr 1fr 1fr"};
  justify-items: center;
  position: relative;

  height: ${(props) =>
    props.fourRows ? "calc(100vh - 56px)" : "calc(100vh - 30px)"};
  padding: 0 0 0 0;
  opacity: 1;
  box-sizing: border-box;
  margin-top: ${(props) => (props.fourRows ? "56px" : "30px")};

  @media (max-width: 900px) {
    grid-template-rows: ${(props) =>
      props.fourRows ? "1fr 1fr 1fr 53px" : "1fr 1fr 1fr"};
    padding: ${(props) => (props.fourRows ? "30px 0 0 0" : "0")};
    margin-top: 0;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const StreamContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 0 0 0;

  @media (max-width: 900px) {
    margin: 0 0 0 0;
  }
`;

const CountdownWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 0 0 0 0;

  @media (max-width: 900px) {
    margin: 0 0 0 0;
  }
`;

const DummyStream = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 35vw;
  height: calc(35vw * 0.5625);
  background-color: hotpink;
`;

const StreamWrapper = styled.div`
  width: 35vw;
  padding: 0 0 0 0;
  position: relative;
  height: calc(35vw * 0.5625);
  opacity: 0;
  transition: opacity 1s ease;

  @media (max-width: 900px) {
    width: calc(100vw - 40px);
    height: calc((100vw - 40px) * 0.5625);
  }

  @media screen and (orientation: portrait) and (min-height: 1000px) and (min-width: 900px) {
    width: 70vw;
    height: calc(70vw * 0.5625);
  }
`;

const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;

  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;

  p {
    font-family: "Univers";
    font-size: 10px;
    margin: 0 0 5px 0;
  }

  @media (max-width: 900px) {
    padding: 0 10px 20px 10px;

    p {
      font-size: 6px;
      margin: 0 0 2px 0;
    }
  }
`;

const TextWrapper = styled.div`
  p {
    &:last-child {
      margin: 0;
    }
  }
`;

const LogosWrapper = styled.img`
  width: 140px;

  @media (max-width: 900px) {
    height: 10px;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  text-align: center;

  img {
    width: 40px;
    margin: 0 7px;
  }

  img:nth-child(2) {
    width: 60px;
  }

  @media (max-width: 900px) {
    img {
      height: 12px;
      width: auto;
      margin: 0 4px;
    }

    img:nth-child(2) {
      width: auto;
    }
  }
`;

const TDWrapper = styled.img`
  margin-left: auto;
  align-self: center;
  width: 250px;

  @media (max-width: 900px) {
    width: auto;
    height: 6px;
  }
`;

const ShadowTitle = styled.img`
  width: 40%;
  max-width: 600px;
  align-self: center;
  justify-self: center;

  opacity: 0;
  transition: opacity 1s ease;

  @media (max-width: 900px) {
    width: calc(100% - 40px);
  }

  @media screen and (orientation: portrait) and (min-height: 1000px) and (min-width: 900px) {
    width: 70%;
    max-width: 700px;
  }
`;

const ShadowTitleCountdown = styled.img`
  width: 40%;
  max-width: 600px;
  align-self: center;
  justify-self: center;

  @media (max-width: 900px) {
    width: calc(100% - 40px);
  }

  @media screen and (orientation: portrait) and (min-height: 1000px) and (min-width: 900px) {
    width: 70%;
    max-width: 700px;
  }
`;

const ShadowName = styled.img`
  width: 25%;
  max-width: 400px;
  align-self: center;
  justify-self: center;
  margin: 0 0 0 0;
  transition: opacity 1s ease;

  @media (max-width: 900px) {
    width: calc(100% - 140px);
  }

  @media screen and (orientation: portrait) and (min-height: 1000px) and (min-width: 900px) {
    width: 50%;
    max-width: 500px;
  }
`;

const Countdown = styled.h1`
  text-align: left;
  font-size: 3rem;
  width: fit-content;
  opacity: 0;
  justify-self: center;
  align-self: center !important;
  transition: opacity 0.1s ease;
  margin: 0;
  color: #888e96;

  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
`;

const IndexPage = () => {
  const countdown = useRef();
  const stream = useRef();
  const streamTitle = useRef();
  const streamMainTitle = useRef();

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const handleResize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const countDownDate = new Date("Jun 23, 2022 11:00:00 GMT-0500").getTime();
  // change to false to enable countdown
  const [isCompleted, setIsCompleted] = useState(false);
  let intervalId = useRef(null);
  const [date, setDate] = useState({
    day: "00",
    hour: "00",
    minute: "00",
    second: "00",
  });

  const checkTime = () => {
    const now = new Date().getTime();

    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setDate({
      day: formatTime(days),
      hour: formatTime(hours),
      minute: formatTime(minutes),
      second: formatTime(seconds),
    });

    if (countdown.current) countdown.current.style.opacity = "1";

    if (distance < 0) {
      setIsCompleted(true);
      stopCountdown();
    }
  };

  useEffect(() => {
    checkTime();
    intervalId.current = setInterval(checkTime, 1000);
  }, []);

  const stopCountdown = () => {
    clearInterval(intervalId.current);
  };

  useEffect(() => {
    if (
      isCompleted &&
      stream.current &&
      streamTitle.current &&
      streamMainTitle.current
    ) {
      stream.current.style.opacity = "1";
      streamTitle.current.style.opacity = "1";
      streamMainTitle.current.style.opacity = "1";
    }
  }, [isCompleted]);

  const formatTime = (value) => {
    if (value < 10) {
      return "0" + String(value);
    } else return value;
  };

  return (
    <>
      <SEO />
      <GlobalStyle />
      <Wrapper fourRows={isCompleted}>
        {isCompleted ? (
          <>
            <ShadowTitle ref={streamMainTitle} src={ShadowTitleLightImg} />
            <StreamContainer>
              <StreamWrapper ref={stream}>
                <iframe
                  src="https://www.youtube.com/embed/GjOQpAuxs8k"
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={"video"}
                ></iframe>
              </StreamWrapper>
            </StreamContainer>
            <ShadowName ref={streamTitle} src={ShadowNameImg} />
          </>
        ) : (
          <>
            <ShadowTitleCountdown src={ShadowTitleLightImg} />
            <Countdown ref={countdown}>
              {date.day}:{date.hour}:{date.minute}:{date.second}
            </Countdown>
            <ShadowName ref={streamTitle} src={ShadowNameImg} />
          </>
        )}
        <Footer>
          <LogosWrapper src={HXOUSELogos} alt="HXOUSE Logos" />
          <IconWrapper>
            <img src={Hexaportal} alt="Hexaportal" />
            <img src={Logo} alt="IN-HXOUSE Conversations Logo" />
            <img src={Loop} alt="Loop" />
          </IconWrapper>
          <TDWrapper src={Sponsors} alt="Sponsors" />
        </Footer>
      </Wrapper>
    </>
  );
};

export default IndexPage;
